<script setup lang="ts">
const images = [
	{
		width: 122,
		height: 103,
		alt: "coin"
	},
	{
		width: 59,
		height: 57,
		alt: "coin"
	},
	{
		width: 60,
		height: 61,
		alt: "coin"
	},
	{
		width: 69,
		height: 67,
		alt: "coin"
	},
	{
		width: 132,
		height: 110,
		alt: "coin"
	}
];
</script>

<template>
	<div class="coin-holder">
		<div v-for="(imageNumber, index) in [1, 2, 3, 4, 5, 4, 1]" :key="index" :class="`coin coin-${index + 1}`">
			<NuxtImg
				:width="images[imageNumber - 1]?.width"
				:height="images[imageNumber - 1]?.height"
				:alt="images[imageNumber - 1]?.alt"
				:src="`/nuxt-img/banners/coins/coin-${imageNumber}.webp`"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
.coin-holder {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

:deep(.coin) {
	position: absolute;
	top: -10%;
	left: calc(50% - 5%);
	max-width: 60px;
	animation: falling 4s linear infinite;

	img {
		width: 100%;
		height: auto;
	}
}

:deep(.coin-1) {
	top: 57%;
	left: -3%;
	max-width: 61px;
	animation: falling 3s linear infinite;

	img {
		animation: 6s levitate-animation infinite ease-in-out;
	}
}

:deep(.coin-2) {
	top: 30%;
	left: 4%;
	animation: falling 5s linear infinite;
	width: 50px;

	img {
		animation: 3s wobble-animation infinite ease-in-out;
	}

	@include media-breakpoint-up(md) {
		left: 20%;
	}
}

:deep(.coin-3) {
	top: 5%;
	left: 20%;
	max-width: 32px;
	animation: falling 2s linear infinite;

	img {
		animation: 8s second-levitate-animation infinite ease-in-out;
	}

	@include media-breakpoint-up(md) {
		left: 35%;
	}
}

:deep(.coin-4) {
	top: 41%;
	left: 30%;
	width: 35px;
	animation: falling 7s linear infinite;

	img {
		animation: 3s wobble-animation infinite ease-in-out;
	}

	@include media-breakpoint-up(md) {
		left: 45%;
	}
}

:deep(.coin-5) {
	top: 2%;
	right: 48%;
	max-width: 58px;
	animation: falling 7.5s linear infinite;

	img {
		animation: 10s third-levitate-animation infinite ease-in-out;
	}

	@include media-breakpoint-up(md) {
		right: auto;
		left: 65%;
	}
}

:deep(.coin-6) {
	animation: falling 8s linear infinite;
	left: 95%;
	width: 44px;

	@include media-breakpoint-up(md) {
		left: 70%;
	}
}

:deep(.coin-7) {
	animation: falling 3s linear infinite;
	left: 45%;
	width: 55px;

	@include media-breakpoint-up(md) {
		left: 90%;
	}
}

@keyframes falling {
	0% {
		top: -10%;
		opacity: 1;
		rotate: -15deg;
	}

	50% {
		rotate: 15deg;
	}

	100% {
		top: 100%;
		opacity: 0;
		rotate: -15deg;
	}
}

@keyframes levitate-animation {
	0%,
	100% {
		transform: rotate(0) scale(1) translateY(0);
	}

	50% {
		transform: rotate(12deg) scale(1.02) translateY(30%);
	}
}

@keyframes second-levitate-animation {
	0%,
	100% {
		transform: rotate(-10deg);
	}

	50% {
		transform: rotate(10deg) scale(1.1) translateY(20%);
	}
}

@keyframes third-levitate-animation {
	0%,
	100% {
		transform: rotate(0);
	}

	50% {
		transform: rotate(15deg) translateY(30%);
	}
}

@keyframes wobble-animation {
	0%,
	100% {
		transform: rotate(9deg);
	}

	50% {
		transform: rotate(-6deg);
	}
}
</style>
